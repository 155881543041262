import React, { Component, useEffect } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './occupierTelephoneValidation.scss';

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'

import {GoogleEvidence,AddressEvidence,YellComEvidence,CompaniesHouseEvidence,RightColumn, LeftColumn, FoodStandardsEvidence, BTDirectoryEvidence, CharityCommisionEvidence, DVSAEvidence, CQCEvidence, JustEatEvidence, One92Evidence, FacebookEvidence, TitleOwnerRow, MDS} from './sharedComponents'
import ProductIcons from '../../../helpers/productIcons'
import ReactModal from 'react-modal'
import PropertyMap from '../../../components/property-map/property-map'
import PageTitle from '../../../components/page-header/pageTitle'
import Twilio from '../../../components/twilio/twilio'

export default view(
    withRouter(
        class OccupierTelephoneValidationSearch extends Component {
            constructor(props) {
                super(props)

                this.state = {
                    accountNumber: null,
                    customer: null,
                    showMap: true,
                    purchasable: null,
                    shouldRefreshMap: false,
                    translations: null,
                    exclusionReason: 'null',
                    workflowModalIsOpen: 'null',
                    exclusionModalIsOpen: 'null',
                    confirmationModalIsOpen: false,
                    customerNumber: null,
                    twilioHeight: '500',
                    MDSData: null,
                    MDSDataReady: true,
                    twillioHidden: false,
                    customMDSPostcode: ''
                }
            }

            componentDidMount() {
                const div = document.getElementById('otv-search');
                this.setState({twilioHeight: div ? div.clientHeight : null})
                useWSProduct('void_sales_alerts');// eslint-disable-line
                request(true).get('/translate?key=otv.').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
                // this.getData()
            }

            clearCompanyData = () => {
                request(true).post(endpoints.OCCUPIER_TELEPHONE_VALIDATION_CLEAR_COMPANY_DATA, {
                    customer: this.state.customer.SPID
                }).then(r => {
                    console.log(r.data.customer);
                    if (r.data.customer && r.data.customer.correctAddress == null) { r.data.customer.correctAddress = r.data.customer.AddressBase_Address}
                    this.setState({
                        customer: r.data.customer,
                        message: r.data.customer ? '' : 'Customer Does Not Exist',
                        showMap: true,
                        purchasable: r.data.customer && r.data.customer.Date_Bought === null,
                        shouldRefreshMap: true
                    }, () => {
                        this.setState({
                            shouldRefreshMap: false,
                            disableActions: false,
                        });
                    });
                }).catch(e => {
                    console.error(e);
                });
            }

            setCustomerNumber = (customerNumber) => {
                this.setState({
                    customerNumber: customerNumber,
                })
            }

            accountNumberChange = (event) => {
                this.setState({
                    accountNumber: event.target.value
                });
            }

            getData = () => {
                this.setState({customerNumber: null, MDSData: null, MDSDataReady: false})
                if (!this.state.accountNumber) return;
                request(true).get(`${endpoints.OCCUPIER_TELEPHONE_VALIDATION_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
                    // console.log(r.data.customer, r.data.customer ? '' : 'Customer Does Not Exist');
                    if (!r.data.customer) window.alert('Could not find SPID.');
                    if (r.data.customer && r.data.customer.correctAddress == null) { r.data.customer.correctAddress = r.data.customer.AddressBase_Address}
                    this.setState({
                        customer: r.data.customer,
                        showMap: true,
                        purchasable: r.data.customer && r.data.customer.Date_Bought === null,
                        shouldRefreshMap: true,
                        assigned_to: r.data.customer ? r.data.customer.assigned_to : null,
                        exclusionReason: r.data.customer ? r.data.customer.Occupier_Exclusion_Reason ? r.data.customer.Occupier_Exclusion_Reason : 'null' : 'null'
                    }, () => {
                        this.setState({ shouldRefreshMap: false });
                    });
                    this.getMDSData(r)
                }).catch(e => {
                    //console.log(e);
                });
            }

            getMDSData = (r) => {
                let postcode
                if (r && r.data.customer) {
                    postcode = r.data.customer.AddressBase_Address.match(/[A-Za-z0-9]{1,4}\s[A-Za-z0-9]{1,4}$/)[0]
                } else if (this.state.customMDSPostcode) {
                    postcode = this.state.customMDSPostcode
                } else {
                    return
                }
                request(true).get(endpoints.OCCUPIER_TELEPHONE_VALIDATION_MDS_SPID, {
                    params: {
                        postcode: postcode
                    }
                }).then(r => {
                    this.setState({MDSData: r.data, MDSDataReady: true})
                }).catch(e => {
                    console.log(e);
                })
            }

            purchaseCustomer = (occupierPurchaseType) => {
                request(true).post(`${endpoints.OCCUPIER_TELEPHONE_VALIDATION_BUY_CUSTOMER}`, {
                    "customer": this.state.customer.SPID,
                    "occupier_purchase_type": occupierPurchaseType,
                }).then(r => {
                    this.setState({
                        customer: r.data.customer,
                        showMap: true,
                        purchasable: r.data.customer.Date_Bought === null,
                        workflowModalIsOpen: 'null',
                    });
                }).catch(e => {
                    console.log(e);
                })
            }

            openWorkflowBuyModal = () => {
                this.setState({
                    workflowModalIsOpen: 'true'
                })
            }

            openExclusionModal = () => {
                this.setState({
                    exclusionModalIsOpen: 'true'
                })
            }

            excludeCustomer = () => {
                if (!this.state.customer || !this.state.customer.SPID) return;
                this.setState({disableActions: true}, () => {
                    request(true).post(endpoints.OCCUPIER_TELEPHONE_VALIDATION_EXCLUDE_CUSTOMER, {
                        customer: this.state.customer.SPID,
                        Occupier_Exclusion_Reason: this.state.exclusionReason
                    }).catch(e => {
                        console.error(e);
                    });
                });
                this.setState({exclusionModalIsOpen: 'null'})
            }

            searchKeyDown = (e) => {
                if (e.which === 13) {
                    this.getData();
                }
            }

            componentWillUnmount() {
                useWSProduct('void_cot_alerts', 'unlisten');// eslint-disable-line
                clearInterval(this.tokenRefresh);
            }

            updateStatus = (event) => {
                if (!this.state.accountNumber) return
                this.setState({
                    customer: {
                        ...this.state.customer,
                        status: event.target.value
                    }
                }, () => {
                    request(true).post(`${endpoints.OCCUPIER_TELEPHONE_VALIDATION_UPDATE_STATUS}`, {
                        customer: this.state.customer.SPID,
                        status: this.state.customer.status
                    }).catch(e => {
                        console.log(e);
                    })
                })
            }

            updateCustomerNumber = (number) => {
                this.setState({customerNumber: number})
            }

            updateCustomer = (customer) => {
                this.setState({customer: customer})
            }

            updateCustomerProperty = (event, property) => {
                this.setState({customer: { ...this.state.customer, [property]: event.target.value}})
            }

            render() {
                return (
                    <div className="grid grid-gap-15" id="void-sales-alerts-page">
                        <Navigation/>
                        <Container width={"97.5vw"}>
                            <PageTitle title="Occupier Telephone Validation - Search" iconClass={ProductIcons.BusinessIcon.props.className} titleRight={
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    { (this.state.customer?.SPID && hasPermission('OCCUPIER_TELEPHONE_VALIDATION', 'PURCHASE')) ?
                                        <button className="navigate" onClick={() => this.setState({confirmationModalIsOpen: true})}>
                                            Clear Data
                                        </button>
                                    : null }
                                    <Link className="navigate" to="/portal/occupier-telephone-validation/">
                                        To Overview
                                    </Link>
                                    { (hasPermission('OCCUPIER_TELEPHONE_VALIDATION', 'WORKFLOW')) ?
                                        <Link className="navigate" to="/portal/occupier-telephone-validation/workflow">
                                            To Workflow
                                        </Link>
                                    : null }
                                    { (hasPermission('OCCUPIER_TELEPHONE_VALIDATION', 'DASHBOARD')) ?
                                        <Link className="navigate" to="/portal/occupier-telephone-validation/dashboard">
                                            To Dashboard
                                        </Link>
                                    : null }
                                </div>
                            } />
                        </Container>
                        <Container width={"97.5vw"}>
                            <div style={{gridTemplateColumns: this.state.twillioHidden ? '1fr min-content' : '4fr 1fr'}} className="vacantCOTAlerts">
                                <div id='otv-search' style={{display: 'grid', gridGap: '15px'}}>
                                    <div className="primary-grid">
                                        <div className="left-grid">
                                            <LeftColumn state={this.state} setCustomerNumber={this.setCustomerNumber} updateCustomerProperty={this.updateCustomerProperty} >
                                                <div className="data-row" style={{gridTemplateColumns: "0fr 2fr 1.15fr"}}>
                                                    <div className="data-row-title">
                                                    { this.state.translations ? this.state.translations['otv.spid'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" name="account-number" value={ this.state.accountNumber } onChange={ this.accountNumberChange } onKeyPress={ this.searchKeyDown }/>
                                                    <button className="search-button" onClick={ this.getData }>
                                                        Search
                                                    </button>
                                                </div>
                                            </LeftColumn>
                                        </div>
                                        <RightColumn state={this.state}>
                                            { (!hasPermission('OCCUPIER_TELEPHONE_VALIDATION', 'PURCHASE') && this.state.purchasable) ?
                                                <button className="buy-button disabled" disabled={true}>
                                                    Occupier Moved In
                                                </button>
                                            :
                                                <button className="buy-button" disabled={ !this.state.purchasable} onClick={ this.openWorkflowBuyModal }>
                                                    { this.state.purchasable === true || this.state.purchasable === null ? "Occupier Moved In" :
                                                        this.state.customer.Bought_By ?
                                                            `Purchased on ${this.state.customer.Date_Bought} by ${this.state.customer.Bought_By}`
                                                        :
                                                            this.state.customer.Date_Bought ?
                                                                `Purchased on ${this.state.customer.Date_Bought}`
                                                            :
                                                                'Unavailable for Purchase'
                                                    }
                                                </button>
                                            }
                                            <select style={{height: 28, marginTop: 7, border: '1px solid var(--primary)', borderRadius: 5}} onChange={_ => { this.setState({exclusionReason: _.target.value}); this.openExclusionModal() }} value={this.state.exclusionReason}>
                                                <option value={'null'}>Select an exclusion reason</option>
                                                <option value={'No Answer'}>No Answer</option>
                                                <option value={'Potentially Vacant'}>Potentially Vacant</option>
                                                <option value={'Address DQI - Insufficient'}>Address DQI - Insufficient</option>
                                                <option value={'Address DQI - Matching'}>Address DQI - Matching</option>
                                                <option value={'Previous Occupier'}>Previous Occupier</option>
                                                <option value={'Adjacent Occupier'}>Adjacent Occupier</option>
                                                <option value={'Other'}>Other</option>
                                            </select>
                                            <div className="data-row">
                                                <div className="data-row-title">
                                                    Status
                                                </div>
                                                <select style={{height: 28, border: '1px solid var(--primary)', borderRadius: 5}} onChange={this.updateStatus} value={this.state.customer?.status ?? "New"}>
                                                    <option value="New">New</option>
                                                    <option value="In progress">In progress</option>
                                                </select>
                                            </div>
                                        </RightColumn>
                                        <TitleOwnerRow state={this.state} updateCustomerProperty={this.updateCustomerProperty} />
                
                                    </div>
                                    <div className="secondary-grid">
                                        <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%', marginBottom: 5}}>
                                            <GoogleEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                            <CompaniesHouseEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                            <YellComEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                            <AddressEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
                                            <FoodStandardsEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                            <BTDirectoryEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                            <CharityCommisionEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                            <DVSAEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
                                            <CQCEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                            <JustEatEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                            <One92Evidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                            <FacebookEvidence data={this.state.customer} setCustomerNumber={this.setCustomerNumber}  updateCustomerProperty={this.updateCustomerProperty}  />
                                        </div>
                                        <div style={{height: 'unset'}}>
                                            <MDS data={this.state.MDSData} MDSDataReady={this.state.MDSDataReady} customPostcode={this.state.customMDSPostcode} setCustomPostcode={(postcode) => this.setState({customMDSPostcode: postcode})} getData={() => this.getMDSData()} />
                                        </div>
                                    </div>
                                </div>
                                <Twilio 
                                    module="occupier_telephone_validation" 
                                    accountNumber={this.state.accountNumber} 
                                    customer={this.state.customer} 
                                    updateCustomer={this.updateCustomer} 
                                    customerNumber={this.state.customerNumber} 
                                    updateCustomerNumber={this.updateCustomerNumber} 
                                    height={this.state.twilioHeight} 
                                    surveyEndpoint={endpoints.OCCUPIER_TELEPHONE_VALIDATION_SUBMIT_ANSWERS} 
                                    twillioHidden={this.state.twillioHidden}
                                    setTwillioHidden={(mode) => this.setState({twillioHidden: mode})}
                                />
                            </div>
                        </Container>
                        <HelpVideo video={session.company.vacant_search_video_url}/>
                        <ReactModal 
                        className="card bulk-buy"
                        isOpen={this.state.exclusionModalIsOpen !== 'null'}>
                            <p>You have selected the following exclusion reason</p>
                            <p>{this.state.exclusionReason}</p>
                            <div className='grid grid-columns-2 grid-gap-5'>
                                <button className='button compact background-red colour-white' onClick={() => this.setState({exclusionModalIsOpen: 'null'})}>Cancel</button>
                                <button className='button compact background-primary colour-white' onClick={() => this.excludeCustomer()}>Confirm</button>
                            </div>
                        </ReactModal>
                        <ReactModal
                        className="card bulk-buy"
                        isOpen={this.state.workflowModalIsOpen !== 'null'}>
                            <p>You have selected to move in an occupier </p>
                            <p>Select either Cancel, IDenteq for identified name or Other for an other name </p>
                            <div className='grid grid-columns-3 grid-gap-5'>
                                <button className='button compact background-red colour-white' onClick={() => this.setState({workflowModalIsOpen: 'null'})}>Cancel</button>
                                <button className='button compact background-primary colour-white' onClick={() => this.purchaseCustomer('IDenteq')}>IDenteq</button>
                                <button className='button compact background-primary colour-white' onClick={() => this.purchaseCustomer('Other')}>Other</button>
                            </div>
                        </ReactModal>
                        <ReactModal
                        className="card bulk-buy"
                        isOpen={this.state.confirmationModalIsOpen}>
                            <h3 style={{textAlign: 'center'}}>Clear data</h3>
                            <p>Are you sure you wish to clear all company data?</p>
                            <p>This will clear everything bar the ABP data, and uneditable data on the right of the screen.</p>

                            <div className='grid grid-columns-2 grid-gap-5'>
                                <button className='button compact background-red colour-white' onClick={() => this.setState({confirmationModalIsOpen: false})}>No</button>
                                <button className='button compact background-primary colour-white' onClick={() => {
                                    this.clearCompanyData() 
                                    this.setState({confirmationModalIsOpen: false})
                                }}>Yes</button>
                            </div>
                        </ReactModal>
                        <Footer />
                    </div>
                )
            }
        }
    )
)

export function DeedPDFViewer({ customer, refresh }) {
    useEffect(() => {
        if (!customer) return;
        useWSProduct('void_sales_alerts', 'listen', `_${customer.Account_Number}`, () => {// eslint-disable-line
            refresh()
        })

        return () => {
            // cleanup
            useWSProduct('void_sales_alerts', 'unregister', `_${customer.Account_Number}`)// eslint-disable-line
        }
    })
    return (
        customer && customer.Bought_By ? customer.DeedPDF ? <div>
            <iframe title="Deed PDF" style={{minHeight: 400, minWidth: '100%'}} src={customer.DeedPDF} />
        </div> : <div style={{height: '100%', minHeight: 400, backgroundColor: 'lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{color: 'white', fontWeight: 700}}>We're processing your deed purchase, it'll appear here automatically once available.</p>
        </div> : <div style={{height: '100%', minHeight: 400, backgroundColor: 'lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{color: 'white', fontWeight: 700}}>Deed available after purchase.</p>
        </div>
    )
}
